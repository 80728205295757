.footer-ul {
list-style-type: none;
padding-left: 0;
}
.footer-ul .footer-ul-link a {
    color: var(--color-dark);
    text-decoration: none;
    transition: all ease-in-out .3s;
}
.footer-ul .footer-ul-link a:hover {
    color: var(--color-black);
    text-decoration: none;
}
.social-ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    gap: 10px;
    margin: 0;
    padding: 0;
}
.social-ul .social-ul-link {    
    border: 2px solid var(--color-dark);
    width: 40px;
    height: 40px;
    color: var(--color-dark);
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all ease-in-out .3s;
}
.social-ul .social-ul-link svg {    
    color: var(--color-dark);
    font-size: 22px;
}
.social-ul .social-ul-link:hover {    
    background-color: var(--color-dark);
}
.social-ul .social-ul-link:hover svg {    
    color: var(--color-white);
}