.offer-coupon-card-icon{
    font-size: 2rem;
    margin-right: .5rem;
}
.text-red{
    color: var(--color-theme);
}
.offer-coupon-card{
    position: relative;
    margin-top: 2.5rem;
    margin-bottom: 3.5rem;
}
.offer-coupon-card .accordion{
    padding-bottom: .5rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    box-shadow: 0 .5rem .8rem rgba(0,0,0,.15)!important;
    border-radius: 0 0 .375rem .375rem;
    background-color: #fff;
}
.offer-coupon-card .accordion-button{
    padding: 10px;
}
.offer-coupon-card .accordion-body{
    padding-top: 10px;
    padding-bottom: 0;
}
.offer-coupon-card .accordion-button:not(.collapsed),
.offer-coupon-card .accordion-button:focus{
    color: #000 !important;
    box-shadow: none !important;
    background: transparent !important;
}

.offer-coupon-card .accordion-button:not(.collapsed)::after,
.offer-coupon-card .accordion-button::after{
    display: none;
}
 .offer-coupon-card .accordion-button.collapsed svg{
    transform: rotate(0deg);
    
} 
 .offer-coupon-card .accordion-button svg{
    transform: rotate(180deg);
}  

.offer-coupon-card ul{
    list-style: disc !important;
    padding-left: 2rem;
    margin: 0;
    padding-bottom: 10px;
}
.offer-coupon-card ul li{
    list-style: disc !important;
    margin-bottom: 10px;
}
.offer-coupon-card ul li:last-child{
    margin-bottom: 0;
}

.offer-coupon-card .card-body{
    padding: 0;
}
.offer-coupon-card .offer-use{
    width: 100%;
    /* position: absolute;
    right: 0;
    top: 0; */
    padding: 10px;
    border-radius: .375rem .375rem 0 0;
    background-color: var(--color-theme);
    color: var(--color-white);
    margin-bottom: .7rem;
    
}