.customer-dashboard-card img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #b9b7b7;
}
.cus-das-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cus-das-btn p {
  width: fit-content;
  background-color: var(--color-theme);
  border-radius: 0.375rem;
  margin-top: 10px;
  color: #fff;
  padding: 5px 10px;
}
.cus-das-btn svg {
  width: 32px;
  height: 100%;
  background-color: var(--color-theme);
  border-radius: 0.375rem;
  margin-top: 10px;
  color: #fff;
}

.frequently-order-card img {
  width: 100% !important;
  height: 300px;
}
.profile-box {
  background-color: var(--color-theme);
}
.profile-box-icon {
  background-color: #fff;
  color: var(--color-theme);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
}
.profile-box-icon svg{
    height: 90px;
    width: 90px;
    position: absolute;
    left: 53%;
    top: 55%;
    transform: translate(-50%,-50%);
}
