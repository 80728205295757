/* .container {
  margin-top: 50px;
}

.form-container {
  padding: 1rem;
}

.form-container h4 {
  font-size: 0.9rem;
}

.form-container .form-field {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.form-container form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.form-container form label {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.form-container input,
.form-container select,
.form-container textarea {
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid #e5e5e5;
}

.default-btn {
  background-color: var(--second-main-color);
  background-color: var(--color-theme);
  border: none;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.3rem 1rem;
  color: #fff;
  transition: 0.5s;
  border: 1px solid var(--color-theme);
}

.default-btn:hover {
  background-color: transparent;
  color: var(--color-theme);
}

.default-card {
  border-radius: 0.5rem;
  padding: 1rem;
}

.default-card img {
  height: 200px !important;
  margin-bottom: 1rem;
} */

.keystring-card{
  padding: 1rem;
    border-radius: 0.375rem;
}