.search-bar-icon {
  background-color: var(--color-theme);
  color: var(--color-white);
  border: 1px solid var(--color-theme);
}

.search-bar-icon:hover {
  background: transparent;
  color: var(--color-theme);
}

.customers-filter {
  padding: 2px 5px !important;
  padding-top: 0 !important;
  font-size: 1.5rem;
  background-color: var(--color-theme) !important;
  border: none;
  color: var(--color-white) !important;
}

.customers-filter:hover {
  background-color: var(--color-theme);
}

.customers-filter::after {
  display: none !important;
}

.order-list-view-name {
  /* width: 20%; */
  width: 15%;
}

.order-list-view-email {
  width: 20%;
}

.customer-search-input {
  width: 83% !important;
}

/* .filter-top-fix {
    position: fixed;
    top: 0;
    right: 15px;
    width: 80%;
    background: #fff !important;
    z-index: 999 !important;
    transform: .9;

} */