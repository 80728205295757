.order-card {
  display: flex;
  padding: 10px;
  border-top: 1px solid #d7d7ed;
  border-left: 1px solid #d7d7ed;
  border-bottom: 1px solid #d7d7ed;
  border-right: 3px solid #aa0019;
  border-radius: 10px;
  box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
  cursor: pointer;
  margin: 10px;
  align-items: center;
}
.slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: #aa0019;
}
.slick-next:before {
    content: '→';
}
.slick-prev:before {
    content: '←';
}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.order-inner-page{
    border-radius: .375rem;
}
.order-inner-page .order-list-view-main{
    height: auto;
}
.order-inner-page .recent-inner{
    display: none !important;
}
.order-inner-page > .card{
    box-shadow: none !important;
}