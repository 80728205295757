/* .form-container input {
    padding: .5rem;
    border-radius: 5px;
}

.container {
    margin-top: 40px;
}

.form-container .form-box {
    border-radius: 5px;
}


.form-container button {
    background-color: var(--color-theme);
    color: #FFF;
    border: 1px solid var(--color-theme);
}

.form-container button:hover {
    background-color: transparent;
    color: var(--color-theme);
}

.form-container input:focus {
    border: 1px solid var(--color-theme);
} */

.property-yes-modal .btn-close{
filter: none !important;
opacity: 1 !important;
}