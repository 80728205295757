.offcanvas .nav-link,
.sidebar-menuitem a {
  margin-right: 0px;
  font-size: 18px;
  padding: 15px 20px;
  border-radius: 25px 0 0 25px;
  position: relative;
  color: var(--color-white) !important;
  transition: 0.4s;
}
.offcanvas .nav-link:hover,
.sidebar-menuitem a:hover {
  background-color: var(--color-white) !important;
  color: var(--color-theme) !important;
  padding: 15px 20px !important;
  border-radius: 25px 0 0 25px !important;
}
.nav-link:last-child,
.nav-link:nth-child(3) {
  margin-right: 0px;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}
.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.header-right .header-profile {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
.header-right .header-profile > .header-info {
  padding-right: 20px;
  text-align: right;
  border-left: 1px solid #eee;
  padding-left: 20px;
}
.header-right .header-profile > .header-info small {
  display: block;
  font-size: 13px;
  color: #89879f;
  font-weight: 400;
  line-height: 2.2;
}
.header-right .header-profile > .header-info span {
  font-size: 20px;
  color: #000;
  display: block;
  font-weight: 500;
}
.header-right .header-profile .profile-pic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
/* .header-right .header-profile .profile-pic:hover {
    background-color: var(--color-dark);
} */
.header-right .header-profile .profile-pic:hover .profile-icon {
  color: var(--color-white);
}
.profile-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.header-right .notification .notification-link {
  position: relative;
  color: #3e4954;
  background: #f4f4f4;
  border-radius: 2rem;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-icon {
  font-size: 20px;
}
.badge {
  line-height: 1.5;
  border-radius: 0.75rem;
  padding: 4px 10px;
  border: 1px solid transparent;
}
.header-right .notification .notification-link .badge {
  position: absolute;
  font-size: 12px;
  border-radius: 50%;
  right: -5px;
  top: -5px;
  padding: 0;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  height: 22px;
  width: 22px;
  background-color: var(--color-theme);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .offcanvas {
    background-color: var(--color-theme);
    color: var(--color-white);
} */
.btn-close {
  filter: invert();
  opacity: 0.75;
}
.nav-link.has-arrow:after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: initial;
  right: 1em;
  -webkit-transform: rotate(135deg) translateY(-50%);
  transform: rotate(135deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
}
.profile-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
.user-options {
  padding-left: 0;
  margin-bottom: 0;
}
.user-options li .nav-link {
  text-transform: capitalize;
  padding: 10px 5px 10px 10px;
  transition: all ease-in-out 0.3s;
  display: block;
  border-radius: 25px 0 0 25px;
}
.user-options li .nav-link:hover {
  background-color: var(--color-light);
  /* color: var(--color-theme); */
}
.notification-dropdown-menu {
  position: absolute;
  top: 100%;
  left: -75%;
  width: 100%;
  max-height: 400px;
  overflow: auto;
}
.notification-list-wrapper {
  padding-left: 0;
  margin-bottom: 0;
}
.notification-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-light);
  padding: 10px 0;
}
.notification-list-wrapper li:last-child .notification-list {
  border-bottom: 0px solid var(--color-light);
  padding-bottom: 0;
}
.notification-list .label {
  text-transform: capitalize;
  font-weight: 600;
}
.notification-list .count {
  background-color: var(--color-theme);
  border-radius: 50%;
  color: var(--color-white);
  width: 30px;
  height: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar-link {
  cursor: pointer;
}
.header-info-mobile {
  display: none;
}
@media (max-width: 568px) {
  /* .bodycollapse .css-1wvake5 {
    width: 180px !important;
    min-width: 100px !important;
  }
  .bodycollapse .css-z5rm24 {
    padding: 0 !important;
  } */
  .header-right .header-profile > .header-info {
    display: none;
  }
  .header-info-mobile {
    justify-content: flex-end;
    display: flex;
  }
  .header-info-mobile small {
    display: block;
    font-size: 13px;
    color: #89879f;
    font-weight: 400;
    line-height: 2.2;
  }
  .header-info-mobile span {
    font-size: 20px;
    color: #000;
    display: block;
    font-weight: 500;
  }
  .profile-dropdown-menu {
    width: 300px;
    max-width: 300px;
    right: 0;
    left: inherit;
  }
}

.download-qr-color-disable {
  color: #80808075 !important;
}
