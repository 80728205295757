/* .container {
    margin-top: 50px;
}

.form-container {
    padding: 1rem;
}

.form-container h4 {
    font-size: 0.9rem;
}

.form-container .form-field {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.form-container form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.form-container form label {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
}

.form-container input,
.form-container select,
.form-container textarea {
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #e5e5e5;
}

.default-btn {
    background-color: var(--second-main-color);
    background-color: var(--color-theme);
    border: none;
    font-weight: bold;
    border-radius: 0.25rem;
    padding: 0.3rem 1rem;
    color: #fff;
    transition: 0.5s;
    border: 1px solid var(--color-theme);
}

.default-btn:hover {
    background-color: transparent;
    color: var(--color-theme);
}

.default-card {
    border-radius: 0.5rem;
    padding: 1rem;
}

.default-card img {
    height: 200px !important;
    margin-bottom: 1rem;
}

.property-card-content h4{
 font-size: 0.8rem !important;
}

.item-property-offcanvas{
    width: 600px !important;
    background-color: #fff !important;
    color: #000 !important;
}

.item-offcanvas-add-btn{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 598px;
    padding: 1rem;
    background-color: #fff;
} */

.item-card img {
    height: auto;
    aspect-ratio: 1/1;
    margin-bottom: 1rem;
}

.item-property-offcanvas .offcanvas-header {
    background-color: var(--color-theme);
    color: #fff;
}

.item-offcanvas-add-btn {
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
}

.addon-option {
    display: flex;
    align-items: center;
}

.addon-option span {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.addon-option span img {
    width: 30px;
    height: 30px;
}

.addon-option img {
    width: 30px;
    height: 30px;
}

.property-card-edit-icon {
    position: absolute !important;
    top: 0;
    right: 0;
    height: auto !important;
}

.property-card-delete-icon {
    position: absolute !important;
    bottom: 0;
    right: 0;
    height: auto !important;
}

.property-card-left-delete-icon {
    position: absolute !important;
    bottom: 0;
    left: 0;
    height: auto !important;
}