.card {
    transition: all ease-in-out .3s;
}
.top-four-card .card-body{
    padding: 1rem 0 !important;
}
.card:hover .dash-card-icons svg {
 animation: icona .5s;
 transform: scale(1);
}
@keyframes icona {
    from{transform: scale(0);}
    to{transform: scale(1);}
}
.card .dash-card-icons:before {
    content: '';
    width: 0%;
    height: 0%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    transition: .3s;
}
.card:hover .dash-card-icons:before {
width: 80%;
height: 80%;
}

.dash-card-icons {
    width: 75px;
    height: 75px;
    background-color: antiquewhite;
 border-radius: 50%;
 padding: 15px;
 display: flex;
 justify-content: center;
 align-items: center;
 border: 0px solid var(--color-theme);
 position: relative;
}
.dash-card-icons svg {
 color: var(--color-theme);
 font-size: 3rem; 
}

.tab-filter {
    border: 2px solid var(--color-dark);
    border-radius: 25px;
}
.tab-filter .nav-link, .tab-filter .nav-item {
    margin-right: 0px;
    font-size: 16px;
}
.nav-pills .nav-link {
    color: var(--color-dark);
    border-radius: 25px;
}
.nav-pills .nav-link.active {
    background-color: var(--color-dark);
    border-radius: 25px;
    color: var(--color-white) !important;
}
.order-list-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 10px;
    transition: all ease-in-out .3s;
    cursor: pointer;
}
.order-list-view:hover {
    box-shadow: 0px 0px 8px rgba(0,0,0,.5);
}
.trending-order-list-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 10px;
    transition: all ease-in-out .3s;
    cursor: pointer;
}
.trending-order-list-view:hover {
    box-shadow: 0px 0px 8px rgba(0,0,0,.5);
}
.trending-item-view {
    width: 93px;
    position: relative;
}
.trending-order-list-view .number {
    width: 42px;
    height: 42px;
    border-radius: 40px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    border: 3px solid #fff;
    line-height: 38px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    background: var(--color-theme);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.clickable-link {
    
    color: #3e4954;
    background: #f4f4f4;
    border-radius: 2rem;
}
.order-item-options .dropdown-toggle::after {
    display: none;
}
.order-item-options .dropdown-toggle.btn {
    border: none;
    padding: 0;
}
.order-item-options .dropdown-toggle.btn:hover {
    border: none;
}
.order-item-options .dropdown-toggle.btn svg {
    font-size: 24px;
}

.recent-order-icon,
.order-item-options {
    font-size: 24px;
    background: transparent !important;
    padding: 0;
    border: none !important;
}
.recent-order-icon:hover{
    border: none !important;
}
.recent-order-icon svg,
.order-item-options svg{
    color: var(--color-theme);
}
.order-list-view-main{
    height: 450px;
    overflow-y: scroll;
    padding-right: .5rem;
}
.order-list-view,
.trending-order-list-view{
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--color-theme);
}
/* .order-list-view:last-child,
.trending-order-list-view:last-child{
    border-bottom: none;
} */




/* Scrollbar Styling */
.order-list-view-main::-webkit-scrollbar {
    width: 3.5px;
}
 
.order-list-view-main::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.order-list-view-main::-webkit-scrollbar-thumb {
    -webkit-border-radius: 15px;
    border-radius: 15px;
    background: var(--color-theme); 
}
.recent-orders-name{
    width: 25%;
}
.nav-pills .nav-link{
    color: var(--color-theme) !important;
}
.nav-pills .nav-link.active{
    background-color: var(--color-theme) !important;
    color: var(--color-white) !important;
}
.tab-filter{
    border-color: var(--color-theme) !important;
}
.customer-dashboard-image{
    filter: blur(5px);
    cursor: not-allowed;
}
.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.image {
  width: 100%;
  height: 310vh;
  margin-bottom: 20px;
  background-image: url('../../assets/images/dashboard-image.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.lock-card{
    max-width: 200px;
    aspect-ratio: 1/1;
    height: 200px;
    margin-top: -14rem;
    margin-left: -4rem;
    position: relative;
}
.lock-card > div{
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.lock-card > div p{
    font-weight: bold;
    color: var(--color-white);
    margin-left: 5px;
    margin-bottom: 5px;
}
.lock-card > div button{
    background: var(--color-white) !important;
    color: var(--color-theme) !important;
    border: none;
    margin-bottom: 2rem;
}
.lock-card .thankyou-text{
    position: absolute;
    top: 45% !important;
}
@media (max-width:992px){
    .tab-filter {
        overflow-x: auto;
        padding: 0;
    }
    .tab-filter .nav-pills{
        width: 150%;
    }
    /* .tab-filter .nav-item a{
        font-size: .6rem;
    } */
    .recent-order-section .card-text{
        overflow-x: auto;
    }
    .recent-order-section .order-list-view-main{
        width: 200%;
    }
}
@media (max-width:767px){
    .customer-dashboard-image{
        height: 100vh;
    }
}