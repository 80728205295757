/* .login-main-page {
    background-image: url();
    padding: 100px;
    background-size: cover;
    background-position: 50%;
    position: relative;
    z-index: 1;
} */
.login-main-page {
  background-image: url(../../assets/images/grub-digest-login-bg.jpg);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.login-wrapper {
  display: -webkit-flex;
  display: flex;
  min-height: 600px;
  height: calc(100vh - 200px);
  box-shadow: 0 0 60px 10px rgba(85, 44, 44, 0.2);
}
.login-main-page .login-wrapper {
  background: var(--color-theme);
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.login-wrapper .login-aside-left {
  max-width: 50%;
  -webkit-flex: 0 0 50%;
  flex: 0 0 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #fff;
  background-image: url(https://koki.dexignzone.com/react/demo/static/media/bg-login2.93927d4b.png);
}
/* .login-wrapper .login-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
} */
.login-wrapper .login-logo {
  margin: 0 auto;
}
.login-wrapper .login-logo-inner {
  background-color: var(--color-white);
  border-radius: 50%;
  height: 220px;
  width: 220px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.login-wrapper .login-aside-left .login-description {
  display: table-cell;
  vertical-align: bottom;
  padding-bottom: 40px;
}
.login-main-page .text-black {
  color: #222 !important;
}
.login-wrapper .social-icons {
  display: -webkit-flex;
  display: flex;
}
.login-wrapper .login-aside-left:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  content: "";
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
}
.login-wrapper .login-aside-right {
  max-width: 50%;
  -webkit-flex: 0 0 50%;
  flex: 0 0 50%;
}
.login-main-page:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.authincation-content {
  background: #fff;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px;
}
.login-wrapper .authincation-content {
  background-color: transparent;
  box-shadow: none;
}
.form-group {
  margin-bottom: 1rem;
}
.login-wrapper .social-icons li {
  margin-right: 10px;
}
.login-wrapper .social-icons li a {
  height: 40px;
  width: 40px;
  color: var(--color-theme);
  border-radius: 40px;
  line-height: 38px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  transition: all 0.5s;
  border: 1px solid var(--color-theme);
}
.form-floating > .form-control {
  height: calc(3rem + 2px);
}
.form-control:focus {
  box-shadow: 0 0 0 0;
}

@media only screen and (max-width: 992px) {
  .login-wrapper .login-aside-left,
  .login-wrapper .login-aside-right {
    max-width: 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .login-wrapper .login-aside-left {
    padding: 50px 30px;
  }
  .login-main-page {
    padding: 0;
    height: auto;
  }
  .login-wrapper {
    display: block;
    height: auto;
  }
  .login-wrapper .login-aside-left,
  .login-wrapper .authincation-content {
    padding: 0 1rem;
  }
  .login-aside-right{
    padding: 2rem 0;
  }
  .login-wrapper .login-logo-inner{
      width: 150px;
      height: 150px;
      margin-bottom: 0;
      padding: 0;
    }
    .login-wrapper .login-logo-inner img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .login-wrapper .login-aside-left .login-description{
        padding-bottom: 1rem;
    }
    .authincation-content-mobile{
      background-color: var(--color-theme);
      padding: 20px;
      width: 70%;
      margin: 0 auto;
    }
    .login-description-mobile{
      position: absolute;
      bottom: 1rem;
      left: 1rem;
    }
}
@media only screen and (max-width: 568px) {
  .authincation-content-mobile{
    width: 100%;
  }
}
.login-password-input{
  position: relative;
}
.login-pass-icon{
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.login-content{
  width: 700px;
}
.extra-text{
  font-weight: 200;
}
.extra-text .no-text-shadow{
  font-weight: 700;
}