body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Yes {
  color: #25D366 !important;
}

.Active {
  color: #25D366 !important;
}

.InActive {
  color: #FF0000 !important;
}

.No {
  color: #FF0000 !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.custom-file-upload {
  border-radius: 0.5rem;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  /* background: linear-gradient(270deg, #3fa1a9, #79f1a4); */
  border: 1px solid lightgray;
  margin-bottom: 15px;
  height: 100%;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 183px;
  overflow: hidden;
  /* border-radius: 50%; */
}

.img-upload:before {
  font-family: "Font Awesome 5 Free";
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #1a586b;
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.img-upload {
  display: flex;
  align-items: center;
  height: 100%;
}

.img-upload img {
  width: 200px !important;
  height: 200px !important;
}

.offcanvas .img-wrap {
  width: 300px;
  height: 300px;
}

.offcanvas .img-upload img {
  width: 100% !important;
  height: auto !important;
}

input[type="file"] {
  display: none;
}


.margin-left {
  margin-left: 5.1rem;
  /* margin-top: 5rem; */
  /* margin-right: 1.1rem; */
  transition: .5s;
}

.bodycollapse .margin-left {
  margin-left: 15.8rem;
}



.section-head {
  margin-bottom: 30px;
}

.section-head h4,
.section-head h5 {
  position: relative;
  padding: 0 0 0 15px;
  line-height: 1;
  border-left: 1px solid #eee;
  letter-spacing: 2px;
  font-size: 38px;
  font-weight: 700;
}

.section-head h5 {
  font-size: initial;
  font-size: 1.2rem;
}

.section-head h4:before,
.section-head h5:before {
  content: "";
  width: 1px;
  height: 35px;
  background: var(--color-theme);
  position: absolute;
  left: -1px;
  bottom: 0;
}

.section-head h5:before {
  height: 10px;
}

.section-head h4 span,
.section-head h5 span {
  font-weight: 200;
  padding-bottom: 5px;
}

.section-head .description {
  padding-bottom: 5px;
}

.dash-heading {
  position: relative;
}

.dash-heading h5 {
  padding: 0 0 0 8px;
  line-height: 1;
  border-left: 1px solid #eee;
}

.dash-heading p:before {
  content: "";
  width: 1px;
  height: 25px;
  background: var(--color-theme);
  position: absolute;
  left: 0px;
  bottom: 5px;
}

.dash-heading p {
  padding: 0 0 0 8px;
  color: var(--color-theme);
}

.error-image {
  width: 30%;
}

.four-o-four {
  height: 90vh;
}

.inner-page-height {
  min-height: 85vh;
}

@media (max-width:568px) {
  .margin-left {
    margin-left: 0;
  }

  .bodycollapse .margin-left {
    margin-left: 10.8rem;
  }

  .bodycollapse .margin-left {
    filter: blur(10px);
  }
}

.download-report {
  color: #fff !important;
}

.download-report:hover,
.download-report:focus {
  background: transparent !important;
  color: var(--color-theme) !important;
}