.sidenavbar {
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  padding-top: 5rem;
  z-index: 6;
  background-color: var(--color-theme);
  overflow-y: auto;
}
.sidenavbar .css-18unl23{
  background: var(--color-theme) !important;
}
.sidenavbar::-webkit-scrollbar {
    width: 3.5px;
}
 
.sidenavbar::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.sidenavbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 15px;
    border-radius: 15px;
    background: var(--color-theme); 
}
.sidenavbar ::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.sidenavbar ::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.sidebar-menuitem {
  font-size: 0.875rem;
  padding: 0.338rem 0.5rem !important;
  font-weight: 400;
  border-radius: 0.375rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 1.5rem;
  border-radius: 0 0 0.375rem 0.375rem;
}

.sidebar-menuitem a {
  height: 36px !important;
  padding: 0 1rem !important;
  border-radius: 0.375rem;
}
.css-ewdv3l{
    overflow-y: auto !important;
}
.css-ewdv3l a {
  width: 100%;
}
.sidebar-menuitem span {
  margin-right: 0;
  display: flex !important;
  justify-content: space-between;
}

.sidebar-icon {
  font-size: 1.2rem !important;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  text-align: center;
  margin-right: 0.75rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}
.css-honxw6,
.css-jn69v9 {
  width: 8px !important;
  height: 8px !important;
}
.css-1wvake5{
    border: none !important;
}
.css-1wvake5.ps-collapsed {
  width: 80px;
  min-width: 80px;
}

.css-16jesut:hover .css-uocmrg {
  visibility: visible !important;
}

.ps-collapsed .css-ewdv3l > a {
  display: block;
}

.css-dip3t8 {
  background: transparent !important;
}

.css-z5rm24 {
  background: transparent !important;
  /* border: 1px solid #fff; */
  padding-left: 1rem;
}
.css-uocmrg,
.css-1jp6z4y {
  background: var(--color-theme) !important;
}
.version-text{
    /* position: fixed;
    left: 0;
    bottom: 0;
    display: block; */
    /* margin-top: auto; */
    color: #fff;
    /* z-index: 9999; */
}



.css-ewdv3l > a{
padding: 0 !important; 
}
.css-ewdv3l > a:hover{
padding: 0 !important;
}
.ps-disabled{
  cursor: not-allowed;
}
.dashboard-lock-icon,
.dashboard-i-icon{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: .375rem;
  cursor: not-allowed;
  cursor: pointer;
}
.dashboard-i-icon{
  width: 30px;
  height: 30px;
  font-size: 1.3rem;
  top: 13%;
  right: 10px;
  left: inherit;
  transform: translate(-13%, 0);
   background: var(--color-theme);
  color: var(--color-white);
}
.dashboard-i-icon svg{
  margin-top: -1.5px;
}
.sidenavbar .css-wlra1l{
  background: var(--color-theme) !important;
}
@media (max-width:568px) {
  /* .bodycollapse .sidebar-menuitem{
    padding: 0 !important;
}
.css-1wvake5.ps-collapsed{
  min-width: 50px !important;
  width: 50px !important;
}
.css-um1o6k {
  right: 2px !important;
  top: 45%;
}
.sidebar-menuitem > .ps-menu-button{
  padding: 0 2px !important;
}
.offcanvas .nav-link:hover, .sidebar-menuitem a:hover {
  padding: 15px 8px !important;
}
.bodycollapse .ps-menu-button{
  padding: 0 10px !important;
} */
.sidenavbar{
  left: -50%;
  transition: 1s;
}
.bodycollapse .sidenavbar{
  left: 0;
  transition: 1s;
}
.bodycollapse body{
overflow-y: hidden !important;
height: 100vh;
}
}