/* .container {
    margin-top: 142px;
}


.form-container {
    padding: 1rem;
}

.form-container h4 {
    font-size: 0.9rem;
}

.form-container .form-field {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.form-container form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.form-container form label {
    margin-left: .5rem;
    margin-bottom: .5rem;
}

.form-container input,
.form-container select,
.form-container textarea {
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #e5e5e5;
}

.default-btn {
    background-color: var(--second-main-color);
    background-color: var(--color-theme);
    border: none;
    font-weight: bold;
    border-radius: .25rem;
    padding: .3rem 1rem;
    color: #fff;
    transition: .5s;
    border: 1px solid var(--color-theme);
}

.default-btn:hover {
    background-color: transparent;
    color: var(--color-theme);
}

.default-card {
    border-radius: .5rem;
    padding: 1rem;
}

.default-card img {
    height: 200px !important;
    margin-bottom: 1rem;
}
.sequence-btn{
  margin-right: 1rem;  
}
.sequence-btn img{
 width: 28px;
 height: auto; 
}

.sequence-offcanvas{
  background-color: #ffffff;  
} */

.banner-m-card {
    padding: 1rem;
}
.banner-m-card img {
    height: 270px;
    margin-bottom: 1rem;
}