.header-mod {
  color: #fff;
  height: 60vh;
  /* display: flex; */
  /* padding: 20px; */
  /* align-items: center; */
}

#pfpname {
  transition: letter-spacing 0.3s ease;
}
#pfpname:hover {
  letter-spacing: 2px;
}

.notifications {
  background: var(--theme-color-red);
  border-radius: 100%;
  aspect-ratio: 1;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
  transition: transform 2s ease;
}
.notifications:hover {
  transition: transform 2s ease;
}
.header-mod .pfp {
  aspect-ratio: 1;
  width: 40px;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 1s ease;
}
.pfp:hover {
  transform: scale(1.2);
}

.header-mod .center {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.header-mod .center div p {
  margin: 0;
  font-weight: 700;
}

.footer {
  width: 100%;
  /* height: 100px; */
  /* position: fixed; */
  /* bottom: 0; */
  display: flex;
  align-items: center;
}

.text-box {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  background: none;
  padding: 8px 10px;
  width: 95%;
  outline: none;
}

.send-ico {
  width: auto;
  color: #fff;
  background: var(--theme-color-red);
  border-radius: 5px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  cursor: pointer;
}
.chat-box-modal .btn-close {
  filter: initial;
  opacity: 1;
}

.chat-box-modal .content {
  width: 96%;
  top: 100px;
  position: absolute;
  height: 300px;
  text-align: center;
  color: #afafaf;
  overflow: auto;
  padding-right: 10px;
}

.chat-box-modal .content::-webkit-scrollbar {
  width: 3.5px;
}

.chat-box-modal .content::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.chat-box-modal .content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: var(--color-theme);
}

.chat-box-modal .content::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.kot-card-mid-main .offcanvas-body::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.msg-btn {
  color: #fff;
  word-wrap: break-word;
  border-radius: 5px 5px 0 5px;
  display: inline-block;
  max-width: 100%;
  font-size: 14px;
  font-family: "BraahOne";
}
.msg-btn p {
  padding: 10px 15px 0 10px;
  margin: 0;
}
.msg-btn-holder {
  /* padding-bottom: 1rem; */
  width: 100%;
  margin-top: 1rem;
  text-align: right;
}
.receiver-msg {
  background: #4c4c4c;
  float: left;
}
.sender-msg {
  background: var(--theme-color-red);
  /* float: right; */
  text-align: left;
}
.sender-msg p {
  color: var(--color-white);
  font-size: 16px;
}
.message-date-time {
  font-size: 10px;
  margin: 0px 5px 5px 0;
}
.customer-message-count {
  position: relative;
}
.customer-message-count-badge {
  position: absolute;
  top: -8px;
  right: 50%;
  border-radius: 50%;
  padding: 0px 8px;
  color: var(--color-white);
  background-color: var(--color-theme) !important;
  height: 20px;
  width: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-alert-msg {
  font-size: 12px;
}

.delete-btn-color {
  color: var(--color-theme);
}
.pos-bottom-0 {
  bottom: 0 !important;
}
