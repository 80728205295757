.swiper-pagination-bullet-active {
  background-color: var(--color-theme) !important;
}
.kot-main-card {
  border-radius: 0.375rem;
}
.kot-card {
  display: flex;
  align-items: center;
  border-top: 2px solid var(--color-theme);
  border-radius: 0.375rem;
}
.kot-card-mid-main {
  height: 180px;
  overflow-y: auto;
}
.kot-card svg {
  font-size: 3.4rem;
  color: var(--color-theme);
  margin-right: 2rem;
}
.kot-card-mid p {
  font-size: 1rem;
}
.non-veg-icon svg {
  color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
  padding: 2px;
  font-size: 1rem;
}
/* .kot-card-mid p:last-child svg{
  color: #008000 !important;
} */
.veg-icon svg {
  color: #008000 !important;
}
.kot-card-mid-2 p:first-child svg {
  border: none !important;
  padding: 0;
}
.kot-card-mid-2 p:last-child svg {
  color: #ffff00;
}
.kot-btn {
  background-color: var(--color-theme);
  color: var(--color-white);
  position: relative;
  overflow: hidden;
  height: auto;
}
.kot-btn-2 {
  box-shadow: none !important;
  border: none !important;
}
.kot-btn svg {
  font-size: 1.5rem;
  margin-top: -3px;
}

.kot-btn::before {
  content: "";
  position: absolute;
  right: 200%;
  top: -30px;
  width: 50px;
  height: 100px;
  background-color: #ffffff30;
  transition: 0.5s;
  z-index: 1;
  transform: rotate(30deg);
}
.kot-btn:hover,
.kot-btn-2:active,
.kot-btn-2:focus,
.kot-btn:focus {
  background-color: var(--color-theme);
  color: #fff;
  border-color: var(--color-theme);
}
.kot-btn:hover::before {
  right: -50% !important;
}
.kot-btn-2:hover::before {
  right: -200% !important;
}
.kot-card-mid-main::-webkit-scrollbar,
.kot-offcanvas .offcanvas-body::-webkit-scrollbar {
  width: 3.5px;
}

.kot-card-mid-main::-webkit-scrollbar-track,
.kot-offcanvas .offcanvas-body::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.kot-card-mid-main::-webkit-scrollbar-thumb,
.kot-offcanvas .offcanvas-body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: var(--color-theme);
}
.kot-card-mid-main ::-webkit-scrollbar,
.kot-offcanvas .offcanvas-body::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.kot-card-mid-main ::-webkit-scrollbar-thumb,
.kot-card-mid-main .offcanvas-body::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.kot-offcanvas {
  width: 450px !important;
  background-color: #fff !important;
  color: #000 !important;
}

.kot-offcanvas .offcanvas-header {
  background-color: var(--color-theme);
  color: #fff;
}
.kot-offcanvas svg {
  color: #000;
  border: none !important;
}
.kot-offcanvas .non-veg-icon svg {
  color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
  padding: 2px;
  font-size: 1rem;
}
.kot-offcanvas .veg-icon svg {
  color: #008000 !important;
}
/* .kot-offcanvas .btn-close {
    filter: none !important;
    opacity: 1;
} */
.kot-offcanvas .btn-close:focus {
  box-shadow: none !important;
}
.kot-offcanvas h5,
.kot-offcanvas h6 {
  margin: 0 !important;
  font-weight: bold;
}
.kot-offcanvas h6 {
  font-size: 1rem;
}
.kot-offcanvas-box {
  margin-bottom: 1rem;
}
.kot-offcanvas-card-main {
  height: auto;
}
.kot-offcanvas-card-main svg {
  color: #000 !important;
}
.kot-save {
  background: #000;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  margin-top: 1rem;
  text-align: center;
}
.kot-save svg {
  color: #fff !important;
}
.kot-save-top {
  background-color: var(--color-theme);
  border-radius: 0 0 0.375rem 0.375rem;
}


.kot-order-btn-main {
    position: fixed;
    bottom: 0;
    right: 0;
    width: inherit;
    padding: 1rem;
    background-color: #fff;
    border-left: 1px solid #666;
}
.recipe-type.recipe-type {
  width: 20px;
  border-radius: 0;
}