.image-uploader{
  margin: 40px 20px;  
}
.drop-image {
    width: 190px;
}
.dropbox {
    text-align: center;
    padding: 20px;
    width: 90%;
    height: 200px;
    margin: auto;
    margin-top: 8px;
}